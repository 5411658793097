<template>
  <q-card class="form-card" :class="{ '--has-errors': hasErrors }">
    <q-card-section class="q-pb-sm">
      <slot name="top" />

      <h6>{{ title }}</h6>

      <p class="text-subtle">
        {{ message }}
        <slot name="message" />
      </p>

      <slot name="between" />
    </q-card-section>

    <q-card-section>
      <div class="q-gutter-y-sm">
        <slot name="inputs" />
        <slot />
      </div>

      <slot name="bottom" />
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "FormCard",
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: ""
    },
    hasErrors: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.form-card {
  @include transition(border);

  &.--has-errors {
    border: 2px solid color(negative);
  }
}
</style>
